body {
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  padding: 0;
  margin: 0;
  overflow: hidden;
  font-family: "Montserrat", serif;
  /* font-family: "Poppins", serif; */
  /* font-family: "EB Garamond", serif; */
  background-image: url("../public/bk.jpg");
  background-size: cover;

}





.userimg {
  position: absolute;
  bottom: 10%;
  left: -100%;
  z-index: -1;
}

.loader {
  border: 6px solid #f3f3f3;
  border-top: 6px solid #3498db;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.Qrdiv {
  background-color: #867bff;
  width: 400px;
  text-align: center;
  padding: 10% 5%;
  box-shadow: rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset, rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
  border-radius: 20px;
  color: white;
}

.usercontainer
{
  display: flex;
  box-shadow: rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset, rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
  border-radius: 20px;
  background-color: #ffffff;
  width: 900px;
  box-sizing: border-box;
}
.userformleft
{
  width: 50%;
  padding: 5%;

}
.userformright {
 
  width: 50%;
 background-color: #867bff;
 border-top-right-radius: 100px;
 padding: 5%;
 color: white;
  
}
.userformright li
{
  line-height: 1.5;
  font-size: 14px;
}
.excellink
{
  display: flex;
  align-items: center;
  background-color: white;
  padding: 5px;
  max-width: 250px;
  justify-content: space-around;
  border-radius: 20px;
  text-decoration: none;
  border-style: dashed;
  border-width: 1px; 
  border-color: #867bff;
}
.excelimg
{
  width: 50px;
  height: auto;
}

form {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

label {
  margin-bottom: 10px;
  display: block;
 
}

input {
  padding: 15px 0px;
  padding-left: 5px;
  font-size: 16px;
  width: 100%;
  border-style: dashed;
  border-radius: 5px;
  border-width: 1px; 
  background-color: #f3f3ff;
}
.mybtn
{
  width: 100%;
  margin-top: 20px;
  background-color: #867bff;
  color: white;
  outline: none;
  border: none;
  padding: 10px;
  border-radius: 10px;
  font-size: 20px;
}

.qrcodimg{
  width: 300px;
  height: 300px;
   margin: 20px 0;
  }

@media (max-width: 1366px) {
  

.userformleft
{
  width: 50%;
  padding: 2%;

}
.userformright {
 
  width: 50%;
 background-color: #867bff;
 border-top-right-radius: 100px;
 padding: 2%;
 color: white;
  
}
.userformright li
{
  line-height: 1.5;
  font-size: 12px;
}
.excellink
{
  display: flex;
  align-items: center;
  background-color: white;
  padding: 0;
  max-width: 250px;
  justify-content: space-around;
  border-radius: 10px;
  text-decoration: none;
  border-style: dashed;
  border-width: 1px; 
  border-color: #867bff;
}
.excelimg
{
  width: 50px;
  height: auto;
}

form {
  display: flex;
  flex-direction: column;
  gap: 1px;
}

label {
  margin-bottom: 10px;
  display: block;
 
}

input {
  padding: 7px;
  padding-left: 5px;
  font-size: 12px;
  width: 100%;
  border-style: dashed;
  border-radius: 5px;
  border-width: 1px; 
  background-color: #f3f3ff;
}
.mybtn
{
  width: 100%;
  margin-top: 20px;
  background-color: #867bff;
  color: white;
  outline: none;
  border: none;
  padding: 10px;
  border-radius: 10px;
  font-size: 14px;
}

.qrcodimg{
  width: 200px;
  height: 200px;
   margin: 0px 0;
  }

  p{
    font-size: 12px;
  }
  h2,h1{
    font-size: 24px;
  }
  h3,h4{
    font-size: 16px;
  }
}

@media (max-width: 768px) {
  html,
  body {
  overflow: scroll;
  display: block;
  height: auto;
  overflow-x: hidden;
  }

  .Qrdiv {
    background-color: #867bff;
    width: 100%;
    text-align: center;
    padding: 5%;
    border-radius: 0px;
    color: white;
    height: 100vh;
    margin-left: -4.5%;
  }

  .usercontainer
{
 
  flex-wrap: wrap;
  width: 100%;
  box-sizing: border-box;
  height: auto;
  box-sizing: content-box;
}
.userformleft
{
  width: 100%;
  padding: 3%;

}
.userformright {
 
 width: 100%;
 background-color: #867bff;
 border-top-right-radius: 100px;
 padding: 5%;
 color: white;
 font-size: 14px;
}

.userformright li
{
  line-height: 1.5;
  font-size: 12px;
}

input {
  padding: 10px 0px;
  padding-left: 5px;
  font-size: 14px;
  width: 98%;
  
}

.excellink
{
  display: flex;
  align-items: center;
  background-color: white;
  padding: 0px;
  max-width: 200px;
  justify-content: space-around;
  border-radius: 20px;
  text-decoration: none;
  border-style: dashed;
  border-width: 1px; 
  border-color: #867bff;
}
.excelimg
{
  width: 40px;
  height: auto;
}

}

